import { useMatches } from '@remix-run/react';
import { clsx } from 'clsx';
import errorStack from 'error-stack-parser';
import * as React from 'react';
import { ArrowLink } from '~/components/arrow-button.tsx';
import { BlogSection } from '~/components/sections/blog-section.tsx';
import { HeroSection, type HeroSectionProps } from '~/components/sections/hero-section.tsx';
import { images } from '~/images.tsx';
import { type MdxListItem } from '~/types.ts';

function RedBox({ error }: { error: Error }) {
  const [isVisible, setIsVisible] = React.useState(true);
  const frames = errorStack.parse(error);

  return (
    <div
      className={clsx('fixed inset-0 z-10 flex items-center justify-center transition', {
        'pointer-events-none opacity-0': !isVisible
      })}
    >
      <button
        className="absolute inset-0 block h-full w-full bg-black opacity-75"
        onClick={() => setIsVisible(false)}
      />
      <div className="border-lg text-primary relative mx-5vw my-16 max-h-75vh overflow-y-auto rounded-lg bg-red-500 p-12">
        <h2>{error.message}</h2>
        <div>
          {frames.map(frame => (
            <div key={[frame.fileName, frame.lineNumber, frame.columnNumber].join('-')} className="pt-4">
              <h6 className="pt-2">{frame.functionName}</h6>
              <div className="font-mono opacity-75">
                {frame.fileName}:{frame.lineNumber}:{frame.columnNumber}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function ErrorPage({
  error,
  heroProps,
  articles
}: {
  error?: Error;
  heroProps: HeroSectionProps;
  articles?: Array<MdxListItem>;
}) {
  if (articles?.length) {
    Object.assign(heroProps, {
      arrowUrl: '#articles',
      arrowLabel: 'Espera, hay más!'
    });
  }
  return (
    <>
      <noscript>
        <div
          style={{
            backgroundColor: 'black',
            color: 'white',
            padding: 30
          }}
        >
          <h1 style={{ fontSize: '2em' }}>{heroProps.title}</h1>
          <p style={{ fontSize: '1.5em' }}>{heroProps.subtitle}</p>
          <small>Además, este sitio trabaja mucho mejor cuando javascript está habilitado...</small>
        </div>
      </noscript>
      <main className="relative">
        {error && process.env.NODE_ENV === 'development' ? <RedBox error={error} /> : null}
        <HeroSection {...heroProps} />
        {Boolean(articles?.length) ? (
          <>
            <div id="articles" />
            <BlogSection
              articles={articles ?? []}
              title="¿Buscando algo que leer?"
              description="Mira estos artículos."
            />
          </>
        ) : null}
      </main>
    </>
  );
}

function ServerError({ error }: { error?: Error }) {
  const matches = useMatches();
  const last = matches[matches.length - 1];
  const pathname = last?.pathname;

  return (
    <ErrorPage
      error={error}
      heroProps={{
        title: '500 - Oh no, algo no salió bien.',
        subtitle: `Lamentablemente "${pathname}" no esta funcionando ahora.`
      }}
    />
  );
}

function FourOhFour({ articles }: { articles?: Array<MdxListItem> }) {
  const matches = useMatches();
  const last = matches[matches.length - 1];
  const pathname = last?.pathname;

  return (
    <ErrorPage
      articles={articles}
      heroProps={{
        title: '404 - Upss. Encontraste una página que no existe.',
        subtitle: `Esto es embarazoso, "${pathname}" no es una página en ilagine.com.`,
        imageBuilder: images.fourthyFour,
        action: <ArrowLink href="/">Ir al Inicio</ArrowLink>
      }}
    />
  );
}

export { ErrorPage, FourOhFour, ServerError };
